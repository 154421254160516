.dropdownItems{
    font-size: 12px;
    max-width: 220px;
    white-space: normal;
    word-wrap: break-word;
}

#basic-nav-dropdown{
    font-size: 14px;
    color: #000;
}
/* .App {
  text-align: center;
}

/* =============== Alumini Login Design ============= */

body {
  margin: 0;
  padding: 0;
  background-color: #f9fafb;
  color: black;
  font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  overflow-y: auto;
  overflow-x: hidden;
  
}
::-webkit-scrollbar {
  width: 4px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

.icon {
  vertical-align: middle;
  line-height: 1px;
  font-size: 20px;
}
.icon_header {
  vertical-align: middle;
  line-height: 1px;
  font-size: 26px;
}
.icon, .icon_header {
  margin-right: 5px;
}

.close_icon {
  color: red;
  margin-left: 30px;
  margin-top: 10px;
  cursor: pointer;
}

.grid-container {
  display: grid;
  grid-template-columns: 260px 3fr;
  grid-template-rows: 0.2fr 3fr;
  grid-template-areas:
    'sidebar header header header'
    'sidebar main main main';
  height: 100vh;
}

/* Header  */
.grid-container.header {
  grid-area: header;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 30px;
  box-shadow: 0 6px 7px -3px rgba(5, 40, 39, 0.35);
  background: #5AB1BB !important;
 
}

.sss:hover {
  text-decoration: none;
  color: #010620 !important;
  }
.sss li a {
  text-decoration: none;
  color: #f6f7fa !important;
  }
.menu-icon {
  display: none;
}
/* End Header  */

/* Sidebar  */
#sidebar1 {
  grid-area: sidebar;
  height: 100%;
  background: #5AB1BB !important;
  
  overflow-y: auto;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.sidebar-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px 0px 30px;
  margin-bottom: 30px;
}

.sidebar-title > span {
  display: none;
}

.sidebar-brand {
  margin-top: 15px;
  font-size: 20px;
  font-weight: 700;
}

.sidebar-list {
  padding: 0;
  list-style-type: none;
}

#sidebar.sidebar-list-item {
  padding: 20px 20px 20px 20px;
  font-size: 18px;
  color: #e0e4ff !important;
}

#sidebar.sidebar-list-item:hover {
  background-color: rgba(10, 10, 10, 0.2);
  color: black !important;
  cursor: pointer;
}

#sidebar.sidebar-list-item > a {
  text-decoration: none;
  color: #ffffff !important;
}

.sidebar-responsive {
  display: inline !important;
  position: absolute;
  /*
    we want the z-index of the sidebar higher so that
    the charts are not showing over the sidebar 
    on small screens
  */
  z-index: 12 !important;
}

/* End Sidebar  */

/* Main  */  
.main-container {
  grid-area: main;
  overflow-y: auto;
  padding: 20px 20px;
  color: rgba(255, 255, 255, 0.95);
}

/* End Main  */


/* Medium <= 992px */
  
@media screen and (max-width: 992px) {
  .grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas:
      'header'
      'main';
  }

  #sidebar1 {
    display: none;
  }

  .menu-icon {
    display: inline;
  }

  .sidebar-title > span {
    display: inline;
  }
}
/* Small <= 768px */
  
@media screen and (max-width: 768px) {
  .main-cards {
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 0;
  }

  .charts {
    grid-template-columns: 1fr;
    margin-top: 30px;
  }
}

/* Extra Small <= 576px */

@media screen and (max-width: 576px) {
  .hedaer-left {
    display: none;
  }
}

.ss:hover{
  color: black !important;
}

/* Main  */  
.main1-container {
  grid-area: main;
  overflow-y: auto;
  padding: 20px 20px;
  color: rgba(255, 255, 255, 0.95);
}
/* =============== Alumini Login Design End ============= */



/* ================= Alumini Admin Side Design ======================== */

/* CSS */
.payments-container {
  position: relative;
}

.sub-options {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  display: none;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 1;
}

.payment-option:hover + .sub-options {
  display: block;
}


.icon {
vertical-align: middle;
line-height: 1px;
font-size: 20px;
}
.icon_header {
vertical-align: middle;
line-height: 1px;
font-size: 26px;
}
.icon, .icon_header {
margin-right: 5px;

}

.close_icon {
color: red;
margin-left: 30px;
margin-top: 10px;
cursor: pointer;
}

.grid-container {
display: grid;
grid-template-columns: 260px  3fr;
grid-template-rows: 0.2fr 3fr;
grid-template-areas:
  'sidebar header header header'
  'sidebar main main main';
height: 100vh;
}

/* Header  */
.header {
grid-area: header;
height: 60px;
display: flex;
align-items: center;
justify-content: space-between;
padding: 0 30px 0 30px;
box-shadow: 0 6px 7px -3px rgba(0, 0, 0, 0.35);
background: #ffffff;
}

.menu-icon {
display: none;
}
/* End Header  */

/* Sidebar  */
#sidebar1 {
grid-area: sidebar;
height: 100%;
background: #ffffff;
overflow-y: auto;
transition: all 0.5s;
-webkit-transition: all 0.5s;
overflow-y: auto; /* Enable vertical scrollbar */

}


/* Customize scrollbar */
#sidebar1::-webkit-scrollbar {
width: 3px; /* Width of the scrollbar */
}

#sidebar1::-webkit-scrollbar-track {
background-color: #bfbfbf; /* Color of the scrollbar track */
}

#sidebar1::-webkit-scrollbar-thumb {
background:rgba(144,202,249,1); /* Color of the scrollbar thumb */
border-radius: 10px; /* Border radius of the scrollbar thumb */
}

#sidebar1::-webkit-scrollbar-thumb:hover {
background: rgba(4,126,223,1); /* Color of the scrollbar thumb on hover */
}

.sidebar-title {
display: flex;
justify-content: space-between;
align-items: center;
padding: 15px 30px 0px 30px;
margin-bottom: 30px;
}

.sidebar-title > span {
display: none;
}

.sidebar-brand {
margin-top: 15px;
font-size: 20px;
font-weight: 700;
}

.sidebar-list {
padding: 0;
list-style-type: none;
}

.sidebar-list-item {
padding: 20px 20px 20px 20px;
font-size: 18px;
}

.sidebar-list-item:hover {
background-color:#e6e9ed; 

cursor: pointer;
}


.sidebar-list-item > a {
text-decoration: none;
color: #111b57;
}


.sidebar-responsive {
display: inline !important;
position: absolute;
/*
  we want the z-index of the sidebar higher so that
  the charts are not showing over the sidebar 
  on small screens
*/
z-index: 12 !important;
}

/* End Sidebar  */


/* Main  */  
.main-container {
grid-area: main;
overflow-y: auto;
padding: 20px 20px;
color: rgba(255, 255, 255, 0.95);
}

.main-title {
display: flex;
justify-content: space-between;
color:#495494;
}

.main-cards {
display: grid;
grid-template-columns: 1fr 1fr 1fr 1fr;
gap: 20px;
margin: 15px 0;
}

.card {
display: flex;
flex-direction: column;
justify-content: space-around;
padding: 8px 15px;
border-radius: 5px;
}

.card1 {
display: flex;
flex-direction: column;
justify-content: space-around;
padding: 8px 15px;
border-radius: 5px;
width:300px;
height:300px;
color:black;
background-color: white;
}

.card1:first-child {
background: white;
}
.card1-inner {
display: flex;
align-items: center;
justify-content: space-between;
}

.card1-inner > .card_icon {
font-size: 25px;
size: 20px;

}
.card1:hover {
background-color: #e0e4ff; /* Change background color on hover */
}

.card1:hover h1,
.card1:hover h3,
.card1:hover h4 {
color: black; /* Change text color on hover */
}
.card1:hover .card-inner > .card_icon {
color: black; /* Change icon color on hover */
}











.card:first-child {
background: linear-gradient(90deg, rgba(255,191,150,1) 3%, rgba(254,112,150,1) 99%);
}

.card:nth-child(2) {
background: linear-gradient(90deg, rgba(144,202,249,1) 3%, rgba(4,126,223,1) 99%);
}

.card:nth-child(3) {
background: linear-gradient(90deg, rgba(132,217,210,1) 3%, rgba(7,205,174,1) 99%);
}

.card:nth-child(4) {
background: linear-gradient(90deg, rgba(216,255,150,1) 3%, rgba(53,243,42,1) 99%);
}

.profile-icon.white {
color: rgb(0, 0, 0); /* Set color to white */
}

.card-inner {
display: flex;
align-items: center;
justify-content: space-between;
}

.card-inner > .card_icon {
font-size: 25px;
}
.card:hover{
background-color: #e0e4ff; /* Change background color on hover */
}

.card:hover h1,
.card:hover h3,
.card:hover h4{
color: black; /* Change text color on hover */
}
.card:hover .card-inner  {
color: black; /* Change icon color on hover */
}
.card:hover >.card-icon {
color:black;
}


.charts {
display: grid;
grid-template-columns: 1fr 1fr;
gap: 20px;
margin-top: 60px;
height: 300px;
}

/* End Main  */


/* Medium <= 992px */

@media screen and (max-width: 992px) {
.grid-container {
  grid-template-columns: 1fr;
  grid-template-rows: 0.2fr 3fr;
  grid-template-areas:
    'header'
    'main';
}

#sidebar1 {
  display: none;
}

.menu-icon {
  display: inline;
}

.sidebar-title > span {
  display: inline;
}
}
/* Small <= 768px */

@media screen and (max-width: 768px) {
.main-cards {
  grid-template-columns: 1fr;
  gap: 10px;
  margin-bottom: 0;
}

.charts {
  grid-template-columns: 1fr;
  margin-top: 30px;
}
}

/* Extra Small <= 576px */

@media screen and (max-width: 576px) {
.hedaer-left {
  display: none;
}
}

.logo_image {
margin: 2%;
width: 240px; /* Adjust the width as needed */
height: 50px; /* Maintain aspect ratio */
margin-left: -25px;

}

.icon {
vertical-align: middle;
line-height: 1px;
font-size: 20px;
}
.icon_header {
vertical-align: middle;
line-height: 1px;
font-size: 26px;
}
.icon, .icon_header {
margin-right: 5px;
}

.close_icon {
color: red;
margin-left: 30px;
margin-top: 10px;
cursor: pointer;
}



button{

transition: 0.5s;
cursor: pointer;


}
.save{
font-weight: 600;
left: -20px;
text-transform: uppercase;
letter-spacing: 1px;
width: 20px;
box-shadow: 20px 0px 40px 0px  #63d3a6;
}
.edit{
color: #fff;
width: 180px;
}

button:focus{
outline: none;
}
.lds-ripple {
display: inline-block;
position: relative;
width: 64px;
height: 64px;
}
.lds-ripple div {
position: absolute;
border: 4px solid #fff;
opacity: 1;
border-radius: 50%;
animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
animation-delay: -0.5s;
}

.dropdown-menu {
position: absolute;
top: 100%;
right: 0;
background-color: #fff;
border: 1px solid #ccc;
border-radius: 4px;
box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
padding: 0;
display: none;
}

.dropdown-menu.show {
display: block;
}

.dropdown-menu a {
display: block;
padding: 10px 15px;
color: #333;
text-decoration: none;
transition: background-color 0.3s ease;
}

.dropdown-menu a:hover {
background-color: #f0f0f0;
}
.icon-toggle {
padding: 0; /* Remove padding */
background-color: transparent; /* Set background color to transparent */
border: none; /* Remove border */
}

.profile-icon {
font-size: 24px; /* Adjust font size if needed */
}

.card-icon {
font-size: 32px; /* Increase this value to make the icons bigger */
margin-right: 10px;
}

/* Donut Chart */
.donut-chart {
display: flex;
flex-direction: column;
align-items: center;
}

.donut-chart svg {
circle {
  transition: stroke-width 0.3s;
  
  &:hover {
    stroke-width: 22;
  }
}
}

.items-names {
max-width: 250px;
display: flex;
flex-wrap: wrap;
padding: 0;
list-style: none;
}

.item-name {
min-width: 70px;
margin-right: 10px;
margin-bottom: 5px;
padding-left: 25px;
font-size: 18px;
font-weight: 600;
}

.admin-profile{
display: flex;
justify-content: space-between;
color:#495494;

}

.infoContainer {
padding-left: 15px;
padding-bottom: 40px;
}

/*Table */
.striped-table {
width: 100%;
border-collapse: collapse;
}

.striped-table th,
.striped-table td {
padding: 8px;
border: 1px solid #ddd;
text-align: left;
}

.striped-table th {
background-color: #f8f8f8;
}

.striped-table tr:nth-child(even) {
background-color: #f2f2f2;
}

/*Button colors */
/* CSS classes for different status colors */
.button-approved {
background-color: green;
color: white;
}

.button-pending {
background-color: orange;
color: white;
}

.button-rejected {
background-color: red;
color: white;
}

/* ================= Alumini Admin Side Design End ======================== */



/* ==================== Room Booking Design Start ============================= */

.logo_image {
 
  width: 500px; 
  height: 60px; 
}

.register {
  color: white;
  font-size: 25px;
  margin-top: -10px;
  text-shadow: 1px 1px black;
  font-family:Georgia, 'Times New Roman', Times, serif;

}

.icon {
  vertical-align: middle;
  line-height: 1px;
  font-size: 20px;
}
.icon_header {
  vertical-align: middle;
  line-height: 1px;
  font-size: 26px;
}
.icon, .icon_header {
  margin-right: 5px;
}

.close_icon {
  color: red;
  margin-left: 30px;
  margin-top: 10px;
  cursor: pointer;
}

.grid-container1 {
  display: grid;
  grid-template-columns: 260px 1fr 1fr 1fr;
  grid-template-rows: 0.2fr 3fr;
  grid-template-areas:
    'sidebar header header header'
    'sidebar main main main';
  height: 100vh;
}

.header {
  grid-area: header;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 0px;
  margin-left: -10px;
  box-shadow: 0 6px 7px -3px rgba(5, 40, 39, 0.35);
  background: #5AB1BB;
}

.menu-icon {
  display: none;
}

#sidebar {
  grid-area: sidebar;
  height: 100%;
  background: #5AB1BB;
  width: 250px;
  overflow-y: auto;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.sidebar-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px 0px 30px;
  margin-bottom: 30px;
}

.sidebar-title > span {
  display: none;
}

.sidebar-brand {
 
  font-size: 20px;
  font-weight: 700;
}

.sidebar-list {
  padding: 0;
  list-style-type: none;
}

.sidebar-list-item {
  padding: 20px 20px 20px 20px;
  font-size: 18px;
}

.itemm:hover {
  background-color: rgba(94, 89, 89, 0.2);
  cursor: pointer;
}

.sidebar-list-item > a {
  text-decoration: none;
  color: #ffffff;
}

.sidebar-responsive {
  display: inline !important;
  position: absolute;
 
  z-index: 12 !important;
}
 
.main-container {
  grid-area: main;
  overflow-y: auto;
  padding: 20px 20px;
  color: rgba(255, 255, 255, 0.95);
}

.main-title1 {
  display: flex;
  justify-content: space-between;
  color:#495494;
}

.main-cards1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  margin: 15px 0;
}

.card5 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 8px 15px;
  border-radius: 5px;
}


.card5:first-child {
  background: linear-gradient(90deg, rgba(255,191,150,1) 3%, rgba(254,112,150,1) 99%);
}

.card5:nth-child(2) {
  background: linear-gradient(90deg, rgba(144,202,249,1) 3%, rgba(4,126,223,1) 99%);
}

.card5:nth-child(3) {
  background: linear-gradient(90deg, rgba(132,217,210,1) 3%, rgba(7,205,174,1) 99%);
}

.card5:nth-child(4) {
  background: linear-gradient(90deg, rgba(216,255,150,1) 3%, rgba(53,243,42,1) 99%);
}

.profile-icon.white {
  color: rgb(0, 0, 0); 
}

.card-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-inner > .card_icon {
  font-size: 25px;
}
.card5:hover{
  background-color: #e0e4ff; 
}

.card5:hover h1,
.card5:hover h3,
.card5:hover h4{
  color: black; 
}
.card5:hover .card-inner  {
  color: black; 
}
.card5:hover >.card-icon {
  color:black;
}


  
@media screen and (max-width: 992px) {
  .grid-container1 {
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas:
      'header'
      'main';
  }

  #sidebar {
    display: none;
  }

  .menu-icon {
    display: inline;
  }

  .sidebar-title > span {
    display: inline;
  }
}

  
@media screen and (max-width: 768px) {
  .main-cards1 {
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 0;
  }

  .charts {
    grid-template-columns: 1fr;
    margin-top: 30px;
  }
}



@media screen and (max-width: 576px) {
  .hedaer-left {
    display: none;
  }
}


.card5{
  width: 450px; 
  height: 200px;
  padding: 15px;
  margin-top: -20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  box-shadow: 5px 5px 10px 0px  gray;
  background: #0e0303;
 
  background-repeat: no-repeat, no-repeat;
  background-position: 120% -5%, 200% -40%;
  background-size: 40%, 80%;
  animation: open .5s;
}
@keyframes open {
  0%  {background-position: 166% -25%, 220% -69%;}
  100%{background-position: 120% -5%, 200% -40%;}
}

.field{
  margin: 5px;
  display: flex;
  flex-direction: column;
  }


.custom-file-upload {
  border-radius: 50%;
  display: inline-block;
  position: relative;
  padding: 6px;
  cursor: pointer;
  background: linear-gradient(270deg, #3fa1a9, #79f1a4);
  margin-bottom: 25px;
}

.img-wrap{
  position: relative;
  width: 50px; 
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
}
.img-upload:before{
  content: "\f093";
  font-size: 30px;
  position: absolute;
  padding-top: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #63d3a6;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  opacity: 0;
  transition: .5s ease;
  background-color: #fff;
}
.img-upload:hover:before{
 opacity: 1;
}
img {
  width: auto;
  height: 100%;
}

label{
  text-transform: uppercase;
  font-weight: 700;
  color: #000000;
}

input{
  border-radius: 15px;
  border: 1px solid #b7b7b7;
  padding: 5px 5px 5px 10px;
  font-size: 18px;
  transition: 0.2s;
}
input:focus{
  outline: none;
  border: 1px solid #64d488;
}
input::placeholder{
  color: #bebebe;
}
.name{ 
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  color: #090909;
  max-width: 220px;
  overflow-wrap: break-word;
}
.status{
  text-align: center;
  max-width: 220px;
  overflow-wrap: break-word;
}
button{
  position: relative;
  color: #054231;
  letter-spacing: 1px;
  /* margin: 20px; */
  font-size: 18px;
  padding: 10px;
  text-align: center;
  transition: 0.5s;
  border-radius: 10px;
  cursor: pointer;
  border-radius: 25px;
  border: none;
 
 
}
.save{
  font-weight: 600;
  left: -20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 20px;
  box-shadow: 20px 0px 40px 0px  #63d3a6;
}
.edit{
  color: #fff;
  width: 180px;
}


button:focus{
  outline: none;
}
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 0;
  display: none;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-menu a {
  display: block;
  padding: 10px 15px;
  color: #333;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.dropdown-menu a:hover {
  background-color: #f0f0f0;
}
.icon-toggle {
  padding: 0;
  background-color: transparent; 
  border: none;
}

.profile-icon {
  font-size: 24px;
}
.profile-icon button {
  position: relative;
  color: #054231;
  letter-spacing: 1px;
  margin: 20px;
  font-size: 18px;
  padding: 10px;
  text-align: center;
  transition: 0.5s;
  border-radius: 10px;
  cursor: pointer;
  border-radius: 25px;
  border: none;
}

.profile-icon .save {
  font-weight: 600;
  left: -20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 20px;
  box-shadow: 20px 0px 40px 0px #63d3a6;
}

.profile-icon .edit {
  color: #fff;
  width: 180px;
}

.profile-icon button:hover {
  left: 0;
  width: 180px;
  box-shadow: 0px 0px 20px 0px #63d3a6;
}

.profile-icon button:focus {
  outline: none;
}

.profile-icon .lds-ripple {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.profile-icon .lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.profile-icon .lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

.main1-container {
  grid-area: main;
  overflow-y: auto;
  padding: 20px 20px;
  color: rgba(255, 255, 255, 0.95);
}

.main1-title {
  display: flex;
  justify-content: space-between;
  color:#495494;
}

.main1-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  margin: 15px 0;
}

.card1 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
 
  padding: 8px 15px;
  width: 310px;
  height: 150px;
  border-radius: 5px;
}





.profile1-icon.white {
  color: rgb(0, 0, 0); 
}

.card1-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card1-inner > .card_icon {
  font-size: 25px;
}
.card1:hover{
  background-color: #505fc0; 
}

.card1:hover h1,
.card1:hover h3,
.card1:hover h4{
  color: black; 
}
.card1:hover .card-inner  {
  color: black;
}
.card1:hover >.card-icon {
  color:black;
}
/* ==================== Room Booking Design End ============================= */




/* ==================== Student Dashboard Design Start ============================= */
/* .App {
  text-align: center;
}
*{
  margin: 0px;
  padding: 0px;
  
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo_image {
 
  width: 500px; 
  height: 60px; 
  margin-left: -1px;
}

.register {
  color: white;
  font-size: 20px;
  margin-top: -10px;
  text-shadow: 1px 1px black;
  font-family:Georgia, 'Times New Roman', Times, serif;
    
       
}

.registerr {
  color: white;
  font-size: 18px;
  margin-top: -10px;
  margin-left: 40px;
  text-shadow: 1px 1px black;
  font-family:Georgia, 'Times New Roman', Times, serif;

}


.icon {
  vertical-align: middle;
  line-height: 1px;
  font-size: 20px;
}
.icon_header {
  vertical-align: middle;
  line-height: 1px;
  font-size: 26px;
}
.icon, .icon_header {
  margin-right: 5px;
}

.close_icon {
  color: red;
  margin-left: 30px;
  margin-top: 10px;
  cursor: pointer;
}

.grid-container {
  display: grid;
  grid-template-columns: 260px 1fr 1fr 1fr;
  grid-template-rows: 0.2fr 3fr;
  grid-template-areas:
    'sidebar header header header'
    'sidebar main main main';
  height: 100vh;
}

.header {
  grid-area: header;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 0px;
  margin-left: -40px;
  box-shadow: 0 6px 7px -3px rgba(5, 40, 39, 0.35);
  background: #7ab1b8;
  width: 1050px;
}

.menu-icon {
  display: none;
}

#sidebar {
  grid-area: sidebar;
  height: 100%;
  background: #5AB1BB;
  width: 220px;
  overflow-y: auto;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.sidebar-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px 0px 30px;
  margin-bottom: 30px;
}

.sidebar-title > span {
  display: none;
}

.sidebar-brand {
 
  font-size: 20px;
  font-weight: 700;
}

.sidebar-list {
  padding: 0;
  list-style-type: none;
}

.sidebar-list-item {
  padding: 20px 20px 20px 20px;
  font-size: 18px;
}

.sidebar-list-item:hover {
  background-color: rgba(10, 10, 10, 0.2);
  cursor: pointer;
}

.sidebar-list-item > a {
  text-decoration: none;
  color: #ffffff;
}

.sidebar-responsive {
  display: inline !important;
  position: absolute;
 
  z-index: 12 !important;
}


.main-container {
  grid-area: main;
  overflow-y: auto;
  padding: 20px 20px;
  color: rgba(255, 255, 255, 0.95);
}

.main-title {
  display: flex;
  justify-content: space-between;
  color:#495494;
}

.main-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  margin: 15px 0;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 8px 15px;
  border-radius: 5px;
}


.card:first-child {
  background: linear-gradient(90deg, rgba(255,191,150,1) 3%, rgba(254,112,150,1) 99%);
}

.card:nth-child(2) {
  background: linear-gradient(90deg, rgba(144,202,249,1) 3%, rgba(4,126,223,1) 99%);
}

.card:nth-child(3) {
  background: linear-gradient(90deg, rgba(132,217,210,1) 3%, rgba(7,205,174,1) 99%);
}

.card:nth-child(4) {
  background: linear-gradient(90deg, rgba(216,255,150,1) 3%, rgba(53,243,42,1) 99%);
}

.profile-icon.white {
  color: rgb(0, 0, 0); 
}

.card-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-inner > .card_icon {
  font-size: 25px;
}
.card:hover{
  background-color: #e0e4ff; 
}
.card1 h2{
  color:rgb(255, 255, 255);
  text-shadow: 1px 1px  black;
}
.card1 h3{
  color:#f36709;
  font-size: 15px;

}
.event-detail{
  margin-left: -400px;
}
.event-detail h4{
  color: #f36709;
}
.card:hover h1,
.card:hover h3,
.card:hover h4{
  color: black; 
}
.card:hover .card-inner  {
  color: black; 
}
.card:hover >.card-icon {
  color:black;
}

.charts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 60px;
  height: 300px;
}

  
@media screen and (max-width: 992px) {
  .grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas:
      'header'
      'main';
  }

  #sidebar {
    display: none;
  }

  .menu-icon {
    display: inline;
  }

  .sidebar-title > span {
    display: inline;
  }
}
  
@media screen and (max-width: 768px) {
  .main-cards {
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 0;
  }

  .charts {
    grid-template-columns: 1fr;
    margin-top: 30px;
  }
}


@media screen and (max-width: 576px) {
  .hedaer-left {
    display: none;
  }
}


.card{
  width: 450px; 
  height: 200px;
  padding: 15px;
  margin-top: -20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  box-shadow: 5px 5px 10px 0px  gray;
  background: #0e0303;
 
  background-repeat: no-repeat, no-repeat;
  background-position: 120% -5%, 200% -40%;
  background-size: 40%, 80%;
  animation: open .5s;
}
@keyframes open {
  0%  {background-position: 166% -25%, 220% -69%;}
  100%{background-position: 120% -5%, 200% -40%;}
}
form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.field{
  margin: 5px;
  display: flex;
  flex-direction: column;
  }


.custom-file-upload {
  border-radius: 50%;
  display: inline-block;
  position: relative;
  padding: 6px;
  cursor: pointer;
  background: linear-gradient(270deg, #b1d8db, #79f1a4);
  margin-bottom: 25px;
}

.img-wrap{
  position: relative;
  width: 50px; 
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
}
.img-upload:before{
  content: "\f093";
  font-size: 30px;
  position: absolute;
  padding-top: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #63d3a6;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  opacity: 0;
  transition: .5s ease;
  background-color: #fff;
}
.img-upload:hover:before{
 opacity: 1;
}
img {
  width: auto;
  height: 100%;
}

label{
  text-transform: uppercase;
  font-weight: 700;
  color: #000000;
}

input{
  border-radius: 15px;
  border: 1px solid #b7b7b7;
  padding: 5px 5px 5px 10px;
  font-size: 18px;
  transition: 0.2s;
}
input:focus{
  outline: none;
  border: 1px solid #64d488;
}
input::placeholder{
  color: #bebebe;
}
.name{ 
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  color: #090909;
  max-width: 220px;
  overflow-wrap: break-word;
}
.status{
  text-align: center;
  max-width: 220px;
  overflow-wrap: break-word;
}
button{
  position: relative;
  color: #054231;
  letter-spacing: 1px;
  margin: 20px;
  font-size: 18px;
  padding: 10px;
  text-align: center;
  transition: 0.5s;
  border-radius: 10px;
  cursor: pointer;
  border-radius: 25px;
  border: none;
 
}
.save{
  font-weight: 600;
  left: -20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 20px;
  box-shadow: 20px 0px 40px 0px  #63d3a6;
}
.edit{
  color: #fff;
  width: 180px;
}


button:focus{
  outline: none;
}
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 0;
  display: none;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-menu a {
  display: block;
  padding: 10px 15px;
  color: #333;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.dropdown-menu a:hover {
  background-color: #f0f0f0;
}
.icon-toggle {
  padding: 0; 
  background-color: transparent; 
  border: none; 
}

.profile-icon {
  font-size: 24px;
}
.profile-icon button {
  position: relative;
  color: #054231;
  letter-spacing: 1px;
  margin: 20px;
  font-size: 18px;
  padding: 10px;
  text-align: center;
  transition: 0.5s;
  border-radius: 10px;
  cursor: pointer;
  border-radius: 25px;
  border: none;
}

.profile-icon .save {
  font-weight: 600;
  left: -20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 20px;
  box-shadow: 20px 0px 40px 0px #63d3a6;
}

.profile-icon .edit {
  color: #fff;
  width: 180px;
}

.profile-icon button:hover {
  left: 0;
  width: 180px;
  box-shadow: 0px 0px 20px 0px #63d3a6;
}

.profile-icon button:focus {
  outline: none;
}

.profile-icon .lds-ripple {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.profile-icon .lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.profile-icon .lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

 
.main1-container {

  padding: 20px 20px;
  color: rgba(255, 255, 255, 0.95);
}

.main1-title {
  display: flex;
  justify-content: space-between;
  color:#495494;
}

.main1-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  margin: 15px 0;
}

.card1 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
 
  padding: 8px 15px;
  width: 310px;
  height: 150px;
  border-radius: 5px;
}





.profile1-icon.white {
  color: rgb(0, 0, 0); 
}

.card1-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card1-inner > .card_icon {
  font-size: 25px;
}
.card1:hover{
  background-color: #505fc0; 
}

.card1:hover h1,
.card1:hover h3,
.card1:hover h4{
  color: black; 
}
.card1:hover .card-inner  {
  color: black; 
}
.card1:hover >.card-icon {
  color:black;
} */



/* ==================== Student Dashboard Design End ============================= */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
  /* =================== side bar scroll =================== */
  .sidebar {
    overflow: auto;
  }
  .sidebar::-webkit-scrollbar {
    display: none;
  }
  .sidebar::-webkit-scrollbar {
    width: 0px;
  }
  .sidebar{
    overflow: hidden; 
    overflow-x: scroll; 
  }
/* ============================================================================= */

.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 78px;
  background: #7FB7B5;
  padding: 6px 14px;
  z-index: 99;
  transition: all 0.5s ease;
}
.sidebar.open {
  width: 250px;
}

.sidebar .logo-details {
  height: 60px;
  display: flex;
  align-items: center;
  position: relative;
}

.sidebar .logo-details .icon {
  opacity: 0;
  transition: all 0.5s ease;
}

.sidebar .logo-details .logo_name {
  color: #020202;
  font-size: 20px;
  font-weight: 600;
  opacity: 0;
  transition: all 0.5s ease;
}

.sidebar.open .logo-details .icon,
.sidebar.open .logo-details .logo_name {
  opacity: 1;
}

.sidebar #btn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 22px;
  transition: all 0.4s ease;
  font-size: 23px;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease;
  display: inline-block;
  color: #ffffff;
}

.sidebar #btn:hover {
  /* background: #FFF; */
  color: #0ec2da;
}

.sidebar.open #btn {
  text-align: right;
}

.sidebar i {
  color: #0a0a0a;
  height: 60px;
  min-width: 50px;
  font-size: 28px;
  text-align: center;
  line-height: 60px;
}

.sidebar .nav-list {
  margin-top: 20px;
  height: 100%;
  justify-items: auto;
}

.sidebar li {
  position: relative;
margin-left: -27px !important;
  margin: 8px 0;
  list-style: none;
}

.sidebar li .tooltip {
  position: absolute;
  top: -20px;
  left: calc(100% + 15px);
  z-index: 3;
  /* background: #fff; */
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 400;
  opacity: 0;
  white-space: nowrap;
  pointer-events: none;
  transition: 0s;
}

.sidebar li:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease; 
  top: 50%;
  transform: translateY(-50%);
}

.sidebar.open li .tooltip {
  display: none;
}

.sidebar input {
  font-size: 15px;
  color: #FFF;
  font-weight: 400;
  outline: none;
  height: 50px;
  width: 100%;
  width: 50px;
  border: none;
  border-radius: 12px;
  transition: all 0.5s ease;
  background: #1d1b31;
}

.sidebar.open input {
  padding: 0 20px 0 50px;
  width: 100%;
}

.sidebar .bx-search {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 24px;
  background: #fff;
  color: #050505;
}

.sidebar.open .bx-search:hover {
  background: #1d1b31;
  color: #FFF;
}

.sidebar .bx-search:hover {
  background: #FFF;
  color: #11101d;
}

.sidebar li a {
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  background: rgb(179, 240, 240);
}

.sidebar li a:hover {
  background: rgb(147, 192, 192)
}

.sidebar li a .links_name {
  color: #050505;
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;
}

.sidebar.open li a .links_name {
  opacity: 1;
  pointer-events: auto;
}

.sidebar li a:hover .links_name,
.sidebar li a:hover i {
  transition: all 0.5s ease;
  color: #f7f7fa;
}

.sidebar li i {
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  border-radius: 12px;
margin-left: -5px;
}

.sidebar li.profile {
  position: fixed;
  height: 60px;
  width: 105px;
  left: 0;
  bottom: -8px;
  padding: 10px 14px;
  background: #1d1b31;
  transition: all 0.5s ease;
  overflow: hidden;
}

.sidebar.open li.profile {
  width: 275px;
}

.sidebar li .profile-details {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.sidebar li img {
  height: 45px;
  width: 45px;
  object-fit: cover;
  border-radius: 6px;
  margin-right: 10px;
}

.sidebar li.profile .name,
.sidebar li.profile .locality {
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  white-space: nowrap;
}

.sidebar li.profile .locality {
  font-size: 12px;
}

.sidebar .profile #log_out {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: #1d1b31;
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-radius: 0px;
  transition: all 0.5s ease;
}

.sidebar.open .profile #log_out {
  width: 50px;
  background: none;
}

.home-section {
  position: relative;
  background: #d7eeed;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}

.sidebar.open ~ .home-section {
  left: 250px;
  width: calc(100% - 250px);
}

.home-section .text {
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 18px;
}

/* .home-section .btn {
  padding: 5px 5px 5px 5px;
  height: 50px;
  font-size: 10px;
  margin-right: 20px;
  margin-top: 20px;
  float: right;
  width: 70px;
  border-radius: 90px;
  background-color: rgb(0, 0, 0);
} */

/* .home-section .btn:hover {
  box-shadow: 0 1px 5px 1px rgb(19, 156, 235);
} */

.home-section:active .btn {
  background-color: rgb(65, 49, 209);
}

/* .home-section .btn i {
  color: #fff;
  height: 15px;
  min-width: 50px;
  font-size: 40px;
  text-align: center;
  line-height: 60px;
} */


@media (max-width: 420px) {
  .sidebar li .tooltip {
    display: none;
  }
}
.custom-datepicker .react-datepicker__input-container input {
  height: 35px;
  width: 100% !important;
}

/* ===Reg form css ================= */
.home-section .text3 {
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 18px;
}

.home-section .btn3 {
  padding: 5px 5px 5px 5px;
  font-size: 17px;
  border-radius: 90px;
  background-color: rgb(23, 160, 160);
}

.home-section .btn3:hover {
  box-shadow: 0 1px 5px 1px rgb(1, 78, 76);
  background-color: rgb(2, 41, 41);
}

.home-section:active .btn3 {
  background-color: rgb(2, 41, 41);
}

.home-section .btn3 i {
  color: #fff;
  height: 15px;
  min-width: 50px;
  font-size: 40px;
  text-align: center;
  line-height: 60px;
}
/* Container for the Room component */
.main-container {
  grid-area: main;
  overflow-y: auto;
  padding: 20px 20px;
  color: rgba(255, 255, 255, 0.95);
}

.main-title1 {
  display: flex;
  justify-content: space-between;
  color: #495494;
}

.main-cards1 {   
  gap: 20px;
  margin: 15px 0;
}

.bed-container1 {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.room-card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s;
}

.room-card:hover {
  transform: scale(1.05);
}

.room-card.booked {
  background-color: rgb(97, 96, 96); /* Color for booked beds */
  color: white;
}

.room-card.unoccupied {
  background-color: rgb(207, 219, 207); /* Color for unoccupied beds */
  color: rgb(3, 3, 3);
}

.room-card-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bed-icon {
  margin-bottom: 5px;
}

.error-message {
  color: red;
  margin-top: 10px;
  font-size: 20px;
}

.button-container1 {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}

.card5 {   
  flex-direction: column;
  justify-content: space-around;
  padding: 8px 15px;
  border-radius: 5px;
  width: 270px; /* Adjusted width */
  height: 390px;
}

.card5 {
  flex-direction: column;
  justify-content: space-around;
  padding: 8px 15px;
  border-radius: 5px;
  color: black;
  background-color: white;
}

.card5:first-child {
  background: white;
}

.card5-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-inner > .card_icon {
  font-size: 25px;
  size: 20px;
}

.card5:hover {
  background-color: #e0e4ff; /* Change background color on hover */
}

.card5:hover h1,
.card5:hover h3,
.card5:hover h4 {
  color: black; /* Change text color on hover */
}

.card5:hover .card-inner > .card_icon {
  color: black; /* Change icon color on hover */
}

.card5:first-child {
  background: linear-gradient(90deg, rgba(255,191,150,1) 3%, rgba(254,112,150,1) 99%);
}

.card5:nth-child(2) {
  background: linear-gradient(90deg, rgba(144,202,249,1) 3%, rgba(4,126,223,1) 99%);
}

.card5:nth-child(3) {
  background: linear-gradient(90deg, rgb(169, 248, 242) 3%, rgba(7,205,174,1) 99%);
}

.card5:nth-child(4) {
  background: linear-gradient(90deg, rgba(216,255,150,1) 3%, rgba(53,243,42,1) 99%);
}
.card5:nth-child(5) {
  background: linear-gradient(90deg, rgb(255, 150, 229) 3%, rgb(243, 42, 233) 99%);
}
.card5:nth-child(6) {
  background: linear-gradient(90deg, rgb(150, 255, 220) 3%, rgb(42, 236, 243) 99%);
}
.card5:nth-child(7) {
  background: linear-gradient(90deg, rgb(255, 166, 150) 3%, rgb(243, 89, 42) 99%);
}
.card5:nth-child(8) {
  background: linear-gradient(90deg, rgb(199, 150, 255) 3%, rgb(129, 42, 243) 99%);
}

.profile-icon.white {
  color: rgb(0, 0, 0); /* Set color to white */
}

.card-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-inner > .card_icon {
  font-size: 25px;
}

.card5:hover {
  background-color: #e0e4ff; /* Change background color on hover */
}

.card5:hover h1,
.card5:hover h3,
.card5:hover h4 {
  color: black; /* Change text color on hover */
}

.card5:hover .card-inner  {
  color: black; /* Change icon color on hover */
}

.card5:hover >.card-icon {
  color:black;
}

.room-booking {
  margin: 20px;
}

.room-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;/* Set a maximum height for the container */
  overflow-y: auto;
  padding: 10px; /* Enable vertical scroll */
}

/* Styling for scrollbar */
.room-container::-webkit-scrollbar {
  width: 10px; /* Set width of the scrollbar */
}

/* Track */
.room-container::-webkit-scrollbar-track {
  background: #f1f1f1; /* Set background color for the track */
}

/* Handle */
.room-container::-webkit-scrollbar-thumb {
  background: red; /* Set color for the scrollbar handle */
  border-radius: 5px; /* Add border radius to the scrollbar handle */
}

/* Handle on hover */
.room-container::-webkit-scrollbar-thumb:hover {
  background: darkred; /* Change color on hover */
}


.room {
  border: 1px solid #000;
  padding: 30px;
  text-align: center;
}

.room-card:hover {
  background-color: #e0e0e0; ;
}

.occupied {
  background-color: #cccccc;
}

.unoccupied {
  background-color: rgb(255, 255, 255);
}
.bed-card-inner{
  height: 90px;
  width: 60px;
  border-radius: 5px;
  margin-top: 30px;
  margin-left: 30px;
  margin-bottom: 5px;
}
.button-container {
  text-align: center; /* Center the button horizontally */
  margin-top: 20px; /* Adjust the margin as needed */
}

.bed-container1 {
  display: flex;
  flex-wrap: wrap; /* Allow cards to wrap to the next line */
  justify-content: flex-start; /* Align cards to the start of the container */
  margin-top: 20px; /* Adjust the margin as needed */
}


.bed-card {
  border: 2px solid transparent;
  margin-right: 0px; /* Add margin between each bed card */
  margin-bottom: 20px; /* Add margin below each bed card */
}




.px1-3 {
    margin-left: 570px;
}
.px3-3 {
    margin-left: 510px;
}

.box {
    border-radius: 5px;
    background: rgb(228, 244, 250);
}
/* p{
    text-align: justify;
} */

.box h1,
.box h2,
.box h3 {
    text-align: center;
    font-size: 26px;
    color: rgb(255, 253, 253);
    margin: 0 10px 0 10px;
    position: relative;
    top: -20px;
    border-radius: 5px;
    padding: 5px;
    font-family: cursive;
}



.flexbox .box {
    border: 1px solid #18528b;
    width: 300px;

}

/* .flexbox .box:nth-child(2) {
    border: 1px solid #18528b;
    width: 300px;

}

.flexbox .box:last-child {
    border: 1px solid #18528b;
    width: 300px;
} */

.box h1 {
    background: #18528b;
}

.box h2 {
    background: #18528b;
}

.box h3 {
    background: #18528b;
}

.box p {
    text-align: center;
    padding: 10px 0;
    font-size: 18px;
    margin: 0;
}

.btn-primary {
    margin-left: -10px;
}



.flexbox {
    display: flex;
    flex-wrap: wrap;
    margin: 50px 15px;
    /* justify-content: center; */
}

.flexbox .box {
    width: 300px;
    /* height: 230px;
*/
    box-sizing: border-box;
    margin: 0 15px;
    margin-bottom: 5em;
    /* position: relative; */
}

.flexbox .box:after {
    pointer-events: none;
    position: absolute;
    z-index: -1;
    content: '';
    bottom: -10%;
    left: 5%;
    height: 20px;
    width: 90%;
    background: -webkit-radial-gradient(center, ellipse, rgba(47, 70, 80, 0.35) 0%, rgba(49, 143, 167, 0) 80%);
    background: radial-gradient(ellipse at center, rgba(47, 70, 80, 0.35) 0%, rgba(109, 200, 241, 0) 80%);
}

.contents {
    text-align: justify !important;
    padding: 15px 15px !important;
    font-size: 15px !important;
}

.neww {
    margin-left: 50px;
}

@media (max-width: 768px) {

   

    .neww {
        margin-left: 40px;
    }


    /* .flexbox .box:last-child {
        border: 1px solid #18528b;
        height: 280px;
    } */

    .pxd-3{
      margin-left: px;
    }

}




.pull-right{
    margin-left: 490px;
}
.title{margin-left:22%;
}
.state{
    margin-left: 30px;
}
.loc{display: flex;}

@media (max-width: 768px){
    .info{
   
     border-radius: 10px; padding: 20px; 
     margin: 0 30px;
}
.pull-right{
    margin-left: 100px;
}
.title{margin-left:12%;
}
.state{
    margin-left: -5px;
    margin-top: 20px;;
}
.loc{
    display: block;
}
.maxmin{
    display:contents;
}

}




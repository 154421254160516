@media (max-width: 768px) {
  .section-title {
    margin-left: 40px !important; /* Reset margin for section titles */
    text-align: center; /* Center align section titles */
  }
  .p1 {
    padding: 10px !important; /* Adjust padding for content container */
  }
  .f{
    margin-top: 20px;
  }
 
}


.categories-container {
  border-radius: 7px;
  height: 600px;
  overflow: hidden;
}

.category-box {
  padding: 20px;
}

.category-list {
  list-style-type: none;
  padding-left: 0;
  border: none;
  background-color: white;
  border-radius: 7px;
  height: 400px;
  overflow: auto;
  box-shadow: grey 1px 1px 1px 1px;
}

.category-list::-webkit-scrollbar {
  display: none;
}

.category-list li {
  font-size: 18px;
  padding: 10px;
}

.category-list button {
  border: none;
  background: none;
  display: flex;
  align-items: center;
}

.category-list .arrow-icon {
  margin-right: 5px;
}
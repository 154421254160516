/* Calendar Container */
.rbc-calendar {
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    background-color: #f7f9fc;
    width:1100px;
    height:500px;
}

/* Calendar Header */
.rbc-header {
    color: #000000;
    font-weight: bold;
    text-align: center;
}

/* Calendar Time Slot */
.rbc-time-slot {
    background-color: #f1f3f6;
    border-bottom: 1px solid #e1e3e7;
}

/* Calendar Event */
.rbc-event {
    background-color: #3498db;
    color: #fff;
    border-radius: 4px;
    padding: 4px;
    margin-bottom: 4px;
    border: 1px solid #2980b9;
    font-weight: bold;
}

/* Event Hover Effect */
.rbc-event:hover {
    background-color: #2980b9;
}

/* Calendar Today */
.rbc-today {
    background-color: #00e5c6;
   
}

/* Calendar Toolbar */
.rbc-toolbar {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid #e1e3e7;
}

/* Custom buttons */
.rbc-btn {
    background-color: #3498db;
    color: #fff;
    padding: 8px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

.rbc-btn:hover {
    background-color: #2980b9;
}

/* Navigation Buttons */
.rbc-toolbar button {
    background-color: #3498db;
    color: #fff;
    padding: 6px 12px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

.rbc-toolbar button:hover {
    background-color: #2980b9;
}

.ssss{
    text-align: center;
}
*{ font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;}

.block-container {
    width: 80%;
    margin: auto;
    margin-top: 30px;
    color:black;
  }
  
  .input-container {
    margin-bottom: 20px;
  }
  
  .block-table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 1px;
    
  }
  
  .block-table th, .block-table td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .block-table th {
    background-color: #f2f2f2;
  }
  
  .block-table td {
    font-size: 16px;
  }
  
  .block-table button {
    padding: 8px 16px;
    border: none;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .block-table button:hover {
    opacity: 0.8;
  }
  
  .update-button, .delete-button {
    font-size: 12px;
  }
  
  .delete-button {
    background-color: #dc3545;
  }
  
  .delete-button:hover {
    background-color: #c82333;
  }
  /* Block.css */

.block-container {
    text-align: center;
    margin-top: 50px; /* Adjust margin-top as needed */
  }
  
  .add-button {
    margin-bottom: 20px; /* Adjust margin-bottom as needed */
  }
  
  /* Center modal */
  .modal-dialog {
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 !important; /* Reset default margin */
  }
  
  .modal-content {
    width: 500px; /* Set the width of the modal */
    height:240px;
    border-radius: 15px; /* Add border-radius for a card-like appearance */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add box shadow for depth */
    display: flex;
    flex-direction: column;margin-top: -800px; }
  
 
  .modal-header {
    height: 60px; /* Set the height of the header */
  display: flex;
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  background-color: #e75b69; /* Set background color for header */
  border-bottom: none; /* Remove default border-bottom */
  border-top-left-radius: 15px; /* Round top left corner of the modal */
  border-top-right-radius: 15px;}
  
  .modal-body {
    padding: 20px; /* Add padding for content */
    flex-grow: 1; /* Grow to fill remaining space */
    background-color: white;
    
  }
  
  .modal-footer {
    height: 60px; /* Set the height of the footer */
  background-color: #ffffff; /* Set background color for footer */
  display: flex;
  justify-content: flex-end; /* Align buttons to the right */
  margin-top: auto; /* Push footer to the bottom */
  border-bottom-left-radius: 15px; /* Round bottom left corner of the modal */
  border-bottom-right-radius: 15px; }
  
  .modal-footer button {
    margin-left: 10px; /* Add margin between buttons */
    margin-top: 10px;
    width: 90px;
    height:40px; 
  }

  
.cancel-button {
    background-color: #cccccc; /* Set gray background color */
    color: #ffffff; /* Set text color */
  }
  
  .add-button {
    background-color: #007bff; /* Set blue background color */
    color: #ffffff; /* Set text color */
  }

  /* ====================DATA TABLE CSS===================== */

.custom-datatable .pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-datatable .pagination li {
  list-style: none;
  margin: 0 5px; 
}

 .custom-datatable .pagination .active a {
  background-color:transparent;
  color: #000;
  border-color:black;
  padding: 4px;
} 

.custom-datatable .pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-datatable .pagination a {
  text-decoration: none;
  padding: 4px; 
  border-radius: 4px; 
  text-align: center;
  justify-content: center;
  display: flex;
  color: white;
  border-color: #2f2f41;
  cursor: pointer;
  background-color: #2f2f41;
}


/* Custom styles for the "Showing X of Y entries" text */
.custom-datatable .dataTables_info {
  font-size: 14px;
  font-weight: 500;
  color: rgb(85, 82, 82);
}

/* Custom styles to hide the extra header row */
.custom-datatable thead:last-child {
  display: none;
}

.custom-datatable .dataTables_length label {
  font-size: 14px;
  color: #0a0a0a;
  margin-right: 10px; 
  font-weight: 500;
  margin-left: 10px;
}

/* Custom styles for the select list */
.custom-datatable .dataTables_length select {
  padding: 0px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  background-color: #f7f3f3;
  color: #030303; 
  text-align: center;
  width: 50px;
  
}


 /* Style the table header cells */
 .custom-datatable thead th {
  background: #cfcfd4;
  color: #070707;
  font-size: 15px;
}


.home-section {
  position: relative;
  background-color: #d7eeed;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}

.sidebar.open ~ .home-section {
  left: 250px;
  width: calc(100% - 250px);
}

.home-section .text {
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 18px;
}

.home-section .btn {
  padding: 5px 5px 5px 5px;
  height: 50px;
  font-size: 10px;
  margin-right: 20px;
  margin-top: 20px;
  float: right;
  width: 70px;
  border-radius: 90px;
  background-color: rgb(0, 0, 0);
}

.home-section .btn:hover {
  box-shadow: 0 1px 5px 1px rgb(19, 156, 235);
}

.home-section:active .btn {
  background-color: rgb(65, 49, 209);
}

.home-section .btn i {
  color: #fff;
  height: 15px;
  min-width: 50px;
  font-size: 40px;
  text-align: center;
  line-height: 60px;
}


@media (max-width: 420px) {
  .sidebar li .tooltip {
    display: none;
  }
}

.sidebar-dropdown {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  
  
  .dropdown-list {
    list-style: none;
    /* padding-left: 20px; */
  }
  

  
/* src/components/Job.css */
.job-card {
    border: 1px solid #dee2e6;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
  }
  
  .job-card:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  .card-title {
    color: #333;
    font-weight: 700;
  }
  
  .card-text {
    font-size: 16px;
    margin-bottom: 0.5rem;
  }
  .job-cards-container::-webkit-scrollbar {
    display: none;
  }



  
 

  
@media (max-width: 768px) {
    .section-title {
      margin-left: 40px !important; /* Reset margin for section titles */
      text-align: center; /* Center align section titles */
    }
    .p1 {
      padding: 10px !important; /* Adjust padding for content container */
    }
    .f{
      margin-top: 20px;
    }
   
  }
  
.footer {
    margin-top: 60px;
}

.view {
    margin-left: 42%;
    width: 200px;
}

.vadhu1 {
    color: white;
    margin-left: -550px;
    font-size: 50px;
    margin-top: -30px;
    color: white;
    text-shadow: 3px 3px darkblue;
    font-weight: bold;
}

.vadhu2 {
    background-color: #ee1515;
    margin-left: -500px;
    margin-top: 30px;
}


@media (max-width: 768px) {
    .about {
        margin-top: -100px;
    }

    /* .photo {
        margin-left: 50px;
    } */

    .view {
        /* margin-left: 90px; */
        width: 150px;
    }

    .vadhu1 {
        color: white;
        margin-left: -20px;
        font-size: 40px;
        margin-top: -100px;
        color: white;
        text-shadow: 3px 3px darkblue;
        font-weight: bold;
    }

    .patrika {
        display: none;
    }

    .vadhu2 {
        margin-left: 30px;
        margin-top: 30px;
    }

    .footer {
        margin-top: -70px;
    }
}




  .side-nav {
    background: none; /* remove background */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 60%;
    transform: translateY(-50%);
    right: 0;
    z-index: 1000;
  }
  
  .side-nav ul {
    list-style: none;
    margin: 0;
    padding: 0;

  }
  
  .side-nav li {
    margin-bottom: 20px;
    font-size: 25px;
    margin-right: 0;


  }
  
  .side-nav a {
    color: navajowhite;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 15px;
  }
  
  .side-nav a:hover {
    color: black;
  }
  
  .side-nav svg {
    font-size: 24px;
    margin-right: 10px;
  }
  
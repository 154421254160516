
.his{
    margin-top:140px;
}
.px-3{
    margin-left: 250px ;
}
/* CSS for Mobile View */
    @media (max-width: 768px) {
        .about {
            padding-top: 30px;
           
           
        }
        .section-title {
            margin-left: 140px !important;
            text-align: center;
            margin-top: 50px;

        }
        .col-lg-6 {
            margin-bottom: 30px;
        }
        .px-3{
            padding-left: -60px !important;
    padding-right: 15px !important;
        }
        
.align-items-center{
margin-top: 60px;
}
   .his{
    margin-top: -400px;
   }   
    }

      
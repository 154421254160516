/* Add this to style the modal */

 
  .jobheader{
    text-align: left;  
    font-size: 28px; 
    padding: 8px;
    color: black;
    border-radius: 7px;
    margin-left: 7px;
    margin-right: 7px;
    box-sizing: border-box;
    box-shadow: grey 2px 2px 2px 2px;
    font-weight: 600;
    }
    
    .add{
        text-align: left;  
        color: black;
        border-radius: 7px;
        margin-left: 7px;
        margin-right: 7px;
        box-sizing: border-box;
        padding: 8px;
        box-shadow: grey 2px 2px 2px 2px; 
    }
   
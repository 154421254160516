*{ font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;}

.block-container {
    width: 80%;
    margin: auto;
    margin-top: 30px;
    color:black;
  }
  
  .input-container {
    margin-bottom: 20px;
  }
  
  .block-table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 1px;
    
  }
  
  .block-table th, .block-table td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .block-table th {
    background-color: #f2f2f2;
  }
  
  .block-table td {
    font-size: 16px;
  }
  
  .block-table button {
    padding: 8px 16px;
    border: none;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .block-table button:hover {
    opacity: 0.8;
  }
  
  .update-button, .delete-button {
    font-size: 12px;
  }
  
  .delete-button {
    background-color: #dc3545;
  }
  
  .delete-button:hover {
    background-color: #c82333;
  }
  /* Block.css */

.block-container {
    text-align: center;
    margin-top: 50px; /* Adjust margin-top as needed */
  }
  
  .add-button {
    margin-bottom: 20px; /* Adjust margin-bottom as needed */
  }
  
  /* Center modal */
  .modal-dialog {
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 !important; /* Reset default margin */
  }
  
  .modal-content {
    width: 500px; /* Set the width of the modal */
    height:240px;
    border-radius: 15px; /* Add border-radius for a card-like appearance */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add box shadow for depth */
    display: flex;
    flex-direction: column;margin-top: -800px; }
  
 
  .modal-header {
    height: 60px; /* Set the height of the header */
  display: flex;
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  background-color: #e75b69; /* Set background color for header */
  border-bottom: none; /* Remove default border-bottom */
  border-top-left-radius: 15px; /* Round top left corner of the modal */
  border-top-right-radius: 15px;}
  
  .modal-body {
    padding: 20px; /* Add padding for content */
    flex-grow: 1; /* Grow to fill remaining space */
    background-color: white;
    
  }
  
  .modal-footer {
    height: 60px; /* Set the height of the footer */
  background-color: #ffffff; /* Set background color for footer */
  display: flex;
  justify-content: flex-end; /* Align buttons to the right */
  margin-top: auto; /* Push footer to the bottom */
  border-bottom-left-radius: 15px; /* Round bottom left corner of the modal */
  border-bottom-right-radius: 15px; }
  
  .modal-footer button {
    margin-left: 10px; /* Add margin between buttons */
    margin-top: 10px;
    width: 90px;
    height:40px; 
  }

  
.cancel-button {
    background-color: #cccccc; /* Set gray background color */
    color: #ffffff; /* Set text color */
  }
  
  .add-button {
    background-color: #007bff; /* Set blue background color */
    color: #ffffff; /* Set text color */
  }

  /* ===================== 50 rs approval css============ */
  .acceptable,.unacceptable{
    width: 100%;
    height: auto;
    align-items: center;
    transition: all 1s;
  }
  .acceptable:hover{
    transform: scale(2.2);
    align-content: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
/* complain.css */

@import url("https://fonts.googleapis.com/css2?family=DM+Mono:wght@300;400;500&display=swap");


* {

  font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  
}


h1 {
  color: rgb(14, 14, 14);
  text-align: center;
}

.student-form {
  background-color: #ffffff;
  padding: 20px;
  

}

.error {
  color: #c0392b;
}

.selected-files {
  margin-bottom: 20px;
}

.file-list {
  list-style: none;
  padding: 0;
  max-height: 220px;
  overflow-y: auto;
}

.file-list .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #cacaca;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 5px;
}

.file-list .remove {
  display: grid;
  place-items: center;
  color: #c0392b;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}

.file-list .remove:hover {
  color: #e74c3c;
}

.file-upload-box {
  padding: 20px;
  border: 2px dashed #cacaca;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.file-upload-box h4 {
  margin: 0;
}

.small-text {
  font-size: 12px;
  color: #a3a3a3;
}
fieldset {
  border: none;
}
#form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.field{
  margin: 5px;
  display: flex;
  flex-direction: column;
  }


.custom-file-upload {
  border-radius: 50%;
  display: inline-block;
  position: relative;
  padding: 6px;
  cursor: pointer;
  background: linear-gradient(270deg, #b1d8db, #79f1a4);
  margin-bottom: 25px;
}

.img-wrap{
  position: relative;
  width: 50px; /* Adjusted width for smaller size */
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
}
.img-upload:before{
  content: "\f093";
  font-size: 30px;
  position: absolute;
  padding-top: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #63d3a6;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  opacity: 0;
  transition: .5s ease;
  background-color: #fff;
}
.img-upload:hover:before{
 opacity: 1;
}
img {
  width: auto;
  height: 100%;
}

label{
  text-transform: uppercase;
  font-weight: 700;
  color: #000000;
}

input{
  border-radius: 15px;
  border: 1px solid #b7b7b7;
  padding: 5px 5px 5px 10px;
  font-size: 18px;
  /* width: 100%; */
  transition: 0.2s;
}
input:focus{
  outline: none;
  border: 1px solid #64d488;
}
input::placeholder{
  color: #bebebe;
}

/* ================ download buton ================= */
.download-button {
  background-color: #007bff; /* Primary color */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: block; /* Changed from inline-block to block */
  width: 100%; /* Set width to 100% */
  font-size: 16px;
  margin: 10px 0; /* Adjust margin for better spacing */
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.download-button:hover {
  background-color: #0056b3; /* Darker shade of primary color */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2); /* Add a shadow */
}

.download-button:active {
  background-color: #004080; /* Even darker shade for active state */
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2); /* Adjust shadow on active */
  transform: translateY(2px); /* Slightly move the button down */
}

